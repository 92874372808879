import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import { apiString as API_URL } from '../utils/constants';
import axios from 'axios';
import { handleErrorMessage, setIsLoading } from './shared.slice';
import moment from 'moment';
import OnboarderNote from '../Models/OnboardingWorkflow/OnboarderNote.model';
import { Phone } from '../Models/Shared/Phone.model';
import CallItem from '../Models/OnboardingWorkflow/CallItem.model';
import { DocumentOnboarding } from '../Models/DocumentOnboardings/DocumentOnboarding.model';
import { copyObject, isDefaultDate } from '../utils/common';
import { EngagedProvider } from '../Models/DocumentEngagements/EngagedProvider.model';
import { OnboardingMeansOfEngagementEnum } from '../Enum/OnboardingMeansOfEngagementEnum';
import Authorization from '../utils/Authorization';
import { AppointmentStatusEnum } from '../Enum/AppointmentStatusEnum';
import { Member } from '../Models/Member/Member.model';
import AddToCallList from '../Models/OnboardingWorkflow/AddToCallList.model';
import b64toBlob from 'b64-to-blob';
import { OnboarderStatus } from '../Models/OfficeOnboarding/OnboarderStatus.model';

export const returnSelectedPhone = (phonesList: Phone[]) => {
    return phonesList ? phonesList?.find((phone) => phone.IsPrimary) || phonesList[0] : null;
};

export const returnDocumentOnboarding = (callItem, auth, notes) => {
    let engagedProvider = new EngagedProvider();
    engagedProvider.Id = auth.UserId;
    engagedProvider.Name = auth.UserName;
    engagedProvider.Role = auth.Role;

    let document = new DocumentOnboarding();
    document.MemberId = callItem.MemberId;
    document.MeansOfEngagement = OnboardingMeansOfEngagementEnum.OutreachCall;
    document.CreationTime = moment().format('MM/DD/YYYY hh:mm A');
    document.VisitedTime = moment().format('MM/DD/YYYY hh:mm A');
    document.EngagedProvider = engagedProvider;
    document.Notes = notes;
    return document;
};

export const createNote = (memberId: string, noteType: string, note: string, auth: Authorization) => {
    let newNote = new OnboarderNote();
    newNote.MemberId = memberId;
    newNote.UserId = auth.UserId;
    newNote.UserName = auth.UserName;
    newNote.Editable = true;
    newNote.NoteType = noteType;
    newNote.Note = note;
    return newNote;
};

export const getNewMemberStatusChange = () => {
    return {
        Completed: false,
        EffectiveDate: null,
        FirstName: '',
        FlaggedByUserName: '',
        Id: null,
        LastName: '',
        MemberId: '',
        Note: '',
        OldStatus: null,
        PopulationName: '',
        PopulationNumber: null,
        RequestedDate: null,
        RequestedStatus: null,
        RequiresAdminApproval: true,
    };
};

export const activityOptions = {
    OTHER: 'Other',
    NEW_NUMBER_ADDED: 'New Number Added',
    PREFERRED_TIME_CHANGE: 'Preferred Time Change',
    NUMBER_FLAGGED_UNLIKELY: 'Number Flagged Unlikely',
    INDICATED_SERVICE_REFUSED: 'Indicated Service Refused',
    SYSTEM_SCHEDULER: 'System Scheduler',
    INDICATED_UNREACHABLE: 'Indicated Unreachable',
    SKIPPED_CALL: 'Skipped Call',
    ATTEMPTED_CALL: 'Attempted Call',
    ATTEMPTED_CALL_MEMBER_REACHED: 'Attempted Call - Member Reached',
    ATTEMPTED_CALL_REACHED_3RD_PARTY: 'Attempted Call - Reached 3rd Party',
    ATTEMPTED_CALL_LEFT_VOICEMAIL: 'Attempted Call - Left Voicemail',
    ATTEMPTED_CALL_NO_FULL_VOICEMAIL: 'Attempted Call - No/Full Voicemail',
    ATTEMPTED_CALL_UNABLE_TO_LEAVE_MESSAGE: 'Attempted Call - Unable to Leave Message',
    USER_COMMENT: 'User Comment',
    DEMOGRAPHIC_REQUEST: 'Demographic Research Request',
    MEMBER_STATUS_CHANGE: 'Member Status Change',
};

export const ContactTimeList = [
    { value: 0, label: 'None' },
    { value: 1, label: 'Morning' },
    { value: 2, label: 'Afternoon' },
    { value: 3, label: 'Evening' },
];

export enum CallListTabs {
    CallList = 'CallList',
    Confirmations = 'Confirmations',
}

const onboardingWorkflowSlice = createSlice({
    name: 'onboardingWorkflowSlice',
    initialState: {
        addActivityNoteIsOpen: false,
        addToCallListIsOpen: false,
        addToCallListResults: null,
        addToCallListValues: new AddToCallList(),
        allOnboarders: [],
        appointmentStatus: AppointmentStatusEnum.None,
        callerHistory: [],
        callItem: new CallItem(),
        callItemConfirmations: [],
        callListMemberIsValid: true,
        changeStatusFormIsValid: true,
        changeStatusIsOpen: false,
        confirmationCallItem: new CallItem(),
        demographicsRequestIsOpen: false,
        exporting: false,
        formPhone: new Phone(),
        formPhoneIsValid: true,
        gettingStatus: false,
        listOfMemberCharts: [],
        memberStatusChange: getNewMemberStatusChange(),
        numberOfDays: 2,
        onboarderNote: new OnboarderNote(),
        onboardingProviders: [],
        onboarderSettings: {} as any,
        onboarderStatus: {} as any,
        phoneHelperText: '',
        scheduledCount: 0,
        selectedCallListTab: CallListTabs.CallList,
        selectedPhone: new Phone(),
        selectedNoteTab: 'current',
        showAddPhone: false,
        showAllMemberCharts: false,
        showAppointmentIsConfirmed: false,
        showScheduleAppointment: false,
        showSkipCall: false,
        skipCallReason: '',
        statusChangeError: '',
        todaysCallCount: 0,
    },
    reducers: {
        _setAddActivityNoteIsOpen: (state, action) => {
            state.addActivityNoteIsOpen = action.payload;
        },
        _setAddToCallListIsOpen: (state, action) => {
            state.addToCallListIsOpen = action.payload;
        },
        _setAddToCallListResults: (state, action) => {
            state.addToCallListResults = action.payload;
        },
        _setAddToCallListValues: (state, action) => {
            state.addToCallListValues = action.payload;
        },
        _setAllOnboarders: (state, action) => {
            state.allOnboarders = action.payload;
        },
        _setAppointmentStatus: (state, action) => {
            state.appointmentStatus = action.payload;
        },
        _setCallerHistory: (state, action) => {
            state.callerHistory = action.payload;
        },
        _setCallItem: (state, action) => {
            state.callItem = action.payload;
        },
        _setCallItemConfirmations: (state, action) => {
            state.callItemConfirmations = action.payload;
        },
        _setCallListMemberIsValid: (state, action) => {
            state.callListMemberIsValid = action.payload;
        },
        _setChangeStatusFormIsValid: (state, action) => {
            state.changeStatusFormIsValid = action.payload;
        },
        _setChangeStatusIsOpen: (state, action) => {
            state.changeStatusIsOpen = action.payload;
        },
        _setConfirmationCallItem: (state, action) => {
            state.confirmationCallItem = action.payload;
        },
        _setDemographicsRequestIsOpen: (state, action) => {
            state.demographicsRequestIsOpen = action.payload;
        },
        _setExporting: (state, action) => {
            state.exporting = action.payload;
        },
        _setFormPhone: (state, action) => {
            state.formPhone = action.payload;
        },
        _setFormPhoneIsValid: (state, action) => {
            state.formPhoneIsValid = action.payload;
        },
        _setGettingStatus: (state, action) => {
            state.gettingStatus = action.payload;
        },
        _setListOfMemberCharts: (state, action) => {
            state.listOfMemberCharts = action.payload;
        },
        _setMemberStatusChange: (state, action) => {
            state.memberStatusChange = action.payload;
        },
        _setNumberOfDays: (state, action) => {
            state.numberOfDays = action.payload;
        },
        _setOnboarderNote: (state, action) => {
            state.onboarderNote = action.payload;
        },
        _setOnboardingProviders: (state, action) => {
            state.onboardingProviders = action.payload;
        },
        _setOnboarderSettings: (state, action) => {
            state.onboarderSettings = action.payload;
        },
        _setOnboarderStatus: (state, action) => {
            state.onboarderStatus = action.payload;
        },
        _setPhoneHelperText: (state, action) => {
            state.phoneHelperText = action.payload;
        },
        _setScheduledCount: (state, action) => {
            state.scheduledCount = action.payload;
        },
        _setSelectedCallListTab: (state, action) => {
            state.selectedCallListTab = action.payload;
        },
        _setSelectedNoteTab: (state, action) => {
            state.selectedNoteTab = action.payload;
        },
        _setSelectedPhone: (state, action) => {
            state.selectedPhone = action.payload;
        },
        _setShowAddPhone: (state, action) => {
            state.showAddPhone = action.payload;
        },
        _setShowAllMemberCharts: (state, action) => {
            state.showAllMemberCharts = action.payload;
        },
        _setShowAppointmentIsConfirmed: (state, action) => {
            state.showAppointmentIsConfirmed = action.payload;
        },
        _setShowScheduleAppointment: (state, action) => {
            state.showScheduleAppointment = action.payload;
        },
        _setShowSkipCall: (state, action) => {
            state.showSkipCall = action.payload;
        },
        _setStatusChangeError: (state, action) => {
            state.statusChangeError = action.payload;
        },
        _setSkipCallReason: (state, action) => {
            state.skipCallReason = action.payload;
        },
        _setTodaysCallCount: (state, action) => {
            state.todaysCallCount = action.payload;
        },
    },
});

const {
    _setAddActivityNoteIsOpen,
    _setAddToCallListIsOpen,
    _setAddToCallListResults,
    _setAddToCallListValues,
    _setAllOnboarders,
    _setAppointmentStatus,
    _setCallerHistory,
    _setCallItem,
    _setCallItemConfirmations,
    _setCallListMemberIsValid,
    _setChangeStatusFormIsValid,
    _setChangeStatusIsOpen,
    _setConfirmationCallItem,
    _setDemographicsRequestIsOpen,
    _setExporting,
    _setFormPhone,
    _setFormPhoneIsValid,
    _setGettingStatus,
    _setListOfMemberCharts,
    _setMemberStatusChange,
    _setNumberOfDays,
    _setOnboarderNote,
    _setOnboardingProviders,
    _setOnboarderSettings,
    _setOnboarderStatus,
    _setPhoneHelperText,
    _setScheduledCount,
    _setSelectedPhone,
    _setSelectedCallListTab,
    _setSelectedNoteTab,
    _setShowAddPhone,
    _setShowAllMemberCharts,
    _setShowAppointmentIsConfirmed,
    _setShowScheduleAppointment,
    _setShowSkipCall,
    _setSkipCallReason,
    _setStatusChangeError,
    _setTodaysCallCount,
} = onboardingWorkflowSlice.actions;

export const setAddToCallListResults =
    (addToCallListResults: [CallItem]): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setAddToCallListResults(addToCallListResults));
    };

export const setOnboarderNote =
    (onboarderNote: OnboarderNote): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setOnboarderNote(onboarderNote));
    };

export const openActivityNoteHandler = (): AppThunk => (dispatch, getState) => {
    dispatch(setAddActivityNoteIsOpen(true));
};

export const setCallItem =
    (callItem: CallItem): AppThunk =>
    (dispatch, getState) => {
        dispatch(_setCallItem(callItem));
    };

export const setCallItemConfirmations =
    (callItemConfirmations: any): AppThunk =>
    (dispatch, getState) => {
        dispatch(_setCallItemConfirmations(callItemConfirmations));
    };

export const setAddToCallListValues =
    (addToCallListValues: AddToCallList): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setAddToCallListValues(addToCallListValues));
    };

export const setAppointmentStatus =
    (appointmentStatus: AppointmentStatusEnum): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setAppointmentStatus(appointmentStatus));
    };

export const setCallListMemberIsValid =
    (callListMemberIsValid: boolean): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setCallListMemberIsValid(callListMemberIsValid));
    };

export const setChangeStatusFormIsValid =
    (changeStatusFormIsValid: boolean): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setChangeStatusFormIsValid(changeStatusFormIsValid));
    };

export const setChangeStatusIsOpen =
    (changeStatusIsOpen: boolean): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setChangeStatusIsOpen(changeStatusIsOpen));
    };

export const closeAddActivityNoteHandler = (): AppThunk => (dispatch, getState) => {
    dispatch(setAddActivityNoteIsOpen(false));
};

export const setAddActivityNoteIsOpen =
    (addActivityNoteIsOpen: boolean): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setAddActivityNoteIsOpen(addActivityNoteIsOpen));
    };

export const cancelAddActivityNoteHandler = (): AppThunk => (dispatch, getState) => {
    dispatch(setOnboarderNote(new OnboarderNote()));
    dispatch(setAddActivityNoteIsOpen(false));
};

export const addToCallListHandler = (): AppThunk => (dispatch, getState) => {
    dispatch(_setAddToCallListValues(new AddToCallList()));
    dispatch(setCallListMemberIsValid(true));
    dispatch(setAddToCallListIsOpen(true));
};

export const closeAddToCallListHandler = (): AppThunk => (dispatch, getState) => {
    dispatch(setAddToCallListResults(null));
    dispatch(setAddToCallListIsOpen(false));
};

export const setAddToCallListIsOpen =
    (addToCallListIsOpen: boolean): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setAddToCallListIsOpen(addToCallListIsOpen));
    };

export const setConfirmationCallItem =
    (confirmationCallItem: CallItem): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setConfirmationCallItem(confirmationCallItem));
    };

export const setDemographicsRequestIsOpen =
    (demographicsRequestIsOpen: boolean): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setDemographicsRequestIsOpen(demographicsRequestIsOpen));
    };

export const setExporting =
    (exporting: boolean): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setExporting(exporting));
    };

export const setFormPhone =
    (formPhone: Phone): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setFormPhone(formPhone));
    };

export const setFormPhoneIsValid =
    (formPhoneIsValid: boolean): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setFormPhoneIsValid(formPhoneIsValid));
    };

export const setListOfMemberCharts =
    (listOfMemberCharts: Member[]): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setListOfMemberCharts(listOfMemberCharts));
    };

export const setMemberStatusChange =
    (memberStatusChange: any): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setMemberStatusChange(memberStatusChange));
    };

export const setNumberOfDays =
    (numberOfDays: number): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setNumberOfDays(numberOfDays));
    };

export const setOnboarderSettings =
    (onboarderSettings: any): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setOnboarderSettings(onboarderSettings));
    };

export const setOnboarderStatus =
    (onboarderStatus: any): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setOnboarderStatus(onboarderStatus));
    };

export const setOnboardingProviders =
    (onboardingProviders: any): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setOnboardingProviders(onboardingProviders));
    };

export const setPhoneHelperText =
    (phoneHelperText: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setPhoneHelperText(phoneHelperText));
    };

export const setScheduledCount =
    (scheduledCount: number): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setScheduledCount(scheduledCount));
    };

export const setSelectedPhone =
    (selectedPhone: Phone): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setSelectedPhone(selectedPhone));
    };

export const setSelectedCallListTab =
    (selectedCallListTab: CallListTabs): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setSelectedCallListTab(selectedCallListTab));
    };

export const setSelectedNoteTab =
    (selectedNoteTab: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setSelectedNoteTab(selectedNoteTab));
    };

export const setShowAddPhone =
    (showAddPhone: boolean): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setShowAddPhone(showAddPhone));
    };

export const setShowAllMemberCharts =
    (showAllMemberCharts: boolean): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setShowAllMemberCharts(showAllMemberCharts));
    };

export const setShowAppointmentIsConfirmed =
    (showAppointmentIsConfirmed: boolean): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setShowAppointmentIsConfirmed(showAppointmentIsConfirmed));
    };

export const setShowScheduleAppointment =
    (showScheduleAppointment: boolean): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setShowScheduleAppointment(showScheduleAppointment));
    };

export const setShowSkipCall =
    (showSkipCall: boolean): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setShowSkipCall(showSkipCall));
    };

export const setSkipCallReason =
    (skipCallReason: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setSkipCallReason(skipCallReason));
    };

export const setStatusChangeError =
    (statusChangeError: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setStatusChangeError(statusChangeError));
    };

export const editNoteHandler =
    (note: OnboarderNote): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setOnboarderNote(note));
        dispatch(openActivityNoteHandler());
    };

export const setTodaysCallCount =
    (todaysCalls: number): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setTodaysCallCount(todaysCalls));
    };

export const openChangeStatusHandler =
    (item: CallItem): AppThunk =>
    async (dispatch, getState) => {
        const onboarderStatus = getState().onboardingWorkflowSlice.onboarderStatus;
        const memberStatusChange = getState().onboardingWorkflowSlice.memberStatusChange;
        dispatch(setStatusChangeError(''));
        dispatch(
            setMemberStatusChange({
                ...memberStatusChange,
                Completed: false,
                EffectiveDate: moment().format('MM/DD/YYYY hh:mm A'),
                FirstName: item.FirstName,
                FlaggedByUserName: onboarderStatus.Username,
                LastName: item.LastName,
                MemberId: item.MemberId,
                Note: '',
                OldStatus: item.MemberStatus,
                PopulationName: item.PopulationName,
                PopulationNumber: item.PopulationNumber,
                RequestedDate: moment().format('MM/DD/YYYY hh:mm A'),
                RequestedStatus: '',
                RequiresAdminApproval: true,
            })
        );
        dispatch(setChangeStatusIsOpen(true));
    };

export const showOtherMemberChartsHandler =
    (chartIds: string[]): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setShowAllMemberCharts(true));
        const response = await axios.post(`${API_URL}/onboarding/getmemberinfo`, chartIds);
        dispatch(setListOfMemberCharts(response.data));
    };

export const addActivityNoteHandler =
    (callItem: CallItem, onboarderNote: OnboarderNote, isConfirmation: boolean): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(setIsLoading(true));
            const response = await axios.post(`${API_URL}/onboarding/InsertActivityNote`, onboarderNote);
            const callItemWithUpdatedNotes = { ...callItem, ActivityNotes: [response.data, ...callItem.ActivityNotes] };
            dispatch(updateOnboarderStatus(isConfirmation, callItemWithUpdatedNotes));
            dispatch(setIsLoading(false));
        } catch (error) {
            console.log('error', error);
            dispatch(setIsLoading(false));
        }

        dispatch(closeAddActivityNoteHandler());
    };

export const updateActivityNoteHandler =
    (callItem: CallItem, onboarderNote: OnboarderNote, isConfirmation: boolean): AppThunk =>
    async (dispatch, getState) => {
        try {
            dispatch(setIsLoading(true));
            await axios.post(`${API_URL}/onboarding/UpdateNote`, onboarderNote);
            const callItemWithUpdatedNotes = {
                ...callItem,
                ActivityNotes: callItem.ActivityNotes.map((note: any) => (note.Id === onboarderNote.Id ? onboarderNote : note)),
            };
            dispatch(updateOnboarderStatus(isConfirmation, callItemWithUpdatedNotes));
            dispatch(setIsLoading(false));
        } catch (error) {
            console.log('error', error);
            dispatch(setIsLoading(false));
        }
        dispatch(closeAddActivityNoteHandler());
    };

export const updateOnboarderStatus =
    (isConfirmation: boolean, callItemWithUpdatedNotes: CallItem): AppThunk =>
    async (dispatch, getState) => {
        const callItemConfirmations = getState().onboardingWorkflowSlice.callItemConfirmations;
        const onboarderStatus = getState().onboardingWorkflowSlice.onboarderStatus;
        if (isConfirmation) {
            const newCallItemConfirmations = callItemConfirmations.map((i: any) => (i.Id === callItemWithUpdatedNotes.Id ? callItemWithUpdatedNotes : i));
            dispatch(setConfirmationCallItem(callItemWithUpdatedNotes));
            dispatch(setCallItemConfirmations(newCallItemConfirmations));
        } else {
            const newOnboarderStatus = {
                ...onboarderStatus,
                CallList: onboarderStatus.CallList.map((i: any) => (i.Id === callItemWithUpdatedNotes.Id ? callItemWithUpdatedNotes : i)),
            };
            dispatch(setCallItem(callItemWithUpdatedNotes));
            dispatch(setOnboarderStatus(newOnboarderStatus));
        }
    };

export const incrementCallCount =
    (callItem: CallItem, number: string, onboarderNote: OnboarderNote, isConfirmation: boolean): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setIsLoading(true));
        try {
            const onboarderStatus = getState().onboardingWorkflowSlice.onboarderStatus;
            try {
                const callCount = await axios.get(
                    `${API_URL}/onboarding/IncrementCallCount?onboarderId=${onboarderStatus.Id}&callItemId=${callItem.Id}&number=${number}`
                );
                dispatch(setTodaysCallCount(callCount.data));
            } catch (error) {
                dispatch(handleErrorMessage(error));
            }

            await axios.post(`${API_URL}/onboarding/UpdateCallItem`, callItem);

            const response = await axios.post(`${API_URL}/onboarding/InsertActivityNote`, onboarderNote);
            const callItemWithUpdatedNotes = { ...callItem, ActivityNotes: [response.data, ...callItem.ActivityNotes] };
            dispatch(updateOnboarderStatus(isConfirmation, callItemWithUpdatedNotes));
            dispatch(setIsLoading(false));
        } catch (error) {
            dispatch(handleErrorMessage(error));
            dispatch(setIsLoading(false));
        }
    };

export const getOnboarderCallCount =
    (onboarderId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await axios.get(`${API_URL}/onboarding/GetOnboarderCallCount?onboarderId=${onboarderId}`);
            dispatch(setTodaysCallCount(response.data));
        } catch (error) {
            console.log('getOnboarderCallCount error', error);
            dispatch(handleErrorMessage(error));
        }
    };

export const getScheduledCount =
    (onboarderId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await axios.post(`${API_URL}/onboarding/GetScheduledCount?onboarderId=${onboarderId}`);
            dispatch(setScheduledCount(response.data));
        } catch (error) {
            console.log('getScheduledCount error', error);
            dispatch(handleErrorMessage(error));
        }
    };

export const getOnboarderSettings = (): AppThunk => async (dispatch, getState) => {
    try {
        const response = await axios.get(`${API_URL}/onboarding/GetOnboarderSettings`);
        dispatch(setOnboarderSettings(response.data));
    } catch (error) {
        console.log('error', error);
    }
};

export const getOnboarderStatus =
    (userId: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(_setGettingStatus(true));
        try {
            const response = await axios.post(`${API_URL}/onboarding/GetOnboarderStatus?userId=${userId}`);
            dispatch(setOnboarderStatus(response.data));
            if (response.data.CallList.length > 0) {
                dispatch(setCallItem(response.data.CallList[0]));
                dispatch(setSelectedPhone(returnSelectedPhone(response.data.CallList[0]?.Phones)));
            } else {
                dispatch(setCallItem(new CallItem()));
            }
            dispatch(getOnboarderCallCount(response.data?.Id));
            dispatch(getScheduledCount(response.data?.Id));
            dispatch(_setGettingStatus(false));
        } catch (error) {
            console.log('error', error);
            dispatch(_setGettingStatus(false));
        }
    };

export const addPhone =
    (phone: Phone, note: OnboarderNote, callItem: CallItem, isConfirmation: boolean): AppThunk =>
    async (dispatch, getState) => {
        try {
            const onboarderStatus = getState().onboardingWorkflowSlice.onboarderStatus;
            const callItemConfirmations = getState().onboardingWorkflowSlice.callItemConfirmations;
            const noteResponse = await axios.post(`${API_URL}/onboarding/InsertActivityNote`, note);
            const newValue = { ...callItem, ActivityNotes: [noteResponse.data, ...callItem.ActivityNotes] };

            const response = await axios.post(`${API_URL}/onboarding/addnewphone?callItemId=${callItem.Id}&memberId=${callItem.MemberId}`, phone);
            if (isConfirmation) {
                const updatedCallItem = {
                    ...response.data,
                    AppointmentAddress: newValue.AppointmentAddress,
                    AppointmentDateTime: newValue.AppointmentDateTime,
                    AppointmentEndDateTime: newValue.AppointmentEndDateTime,
                    AppointmentId: newValue.AppointmentId,
                    AppointmentReason: newValue.AppointmentReason,
                    AppointmentStatus: newValue.AppointmentStatus,
                    AppointmentType: newValue.AppointmentType,
                };

                const newCallItemConfirmations = callItemConfirmations.map((item: any) => (item.Id === callItem.Id ? updatedCallItem : item));
                dispatch(setConfirmationCallItem(updatedCallItem));
                dispatch(setCallItemConfirmations(newCallItemConfirmations));
            } else {
                const updatedOnboarderStatus = {
                    ...onboarderStatus,
                    CallList: onboarderStatus.CallList.map((item: any) => (item.Id === response.data.Id ? response.data : item)),
                };
                dispatch(setCallItem(response.data));
                dispatch(setOnboarderStatus(updatedOnboarderStatus));
            }
            dispatch(setSelectedPhone(returnSelectedPhone(response.data.Phones)));
        } catch (error) {
            console.log('error', error);
        }
        dispatch(setShowAddPhone(false));
    };

export const changeTime =
    (callItem: CallItem, onboarderNote: OnboarderNote, isConfirmation: boolean): AppThunk =>
    async (dispatch, getState) => {
        try {
            await axios.post(`${API_URL}/onboarding/UpdateCallItem`, callItem);
            const response = await axios.post(`${API_URL}/onboarding/InsertActivityNote`, onboarderNote);
            const callItemWithUpdatedNotes = { ...callItem, ActivityNotes: [response.data, ...callItem.ActivityNotes] };
            dispatch(updateOnboarderStatus(isConfirmation, callItemWithUpdatedNotes));
        } catch (error) {
            console.log('error', error);
        }
        dispatch(closeAddActivityNoteHandler());
    };

export const insertMemberStatusChange =
    (callItem: CallItem, memberStatusChange: any, note: OnboarderNote, isConfirmation: boolean): AppThunk =>
    async (dispatch, getState) => {
        try {
            await axios.post(`${API_URL}/member/InsertMemberStatusChange`, memberStatusChange);
            dispatch(addActivityNoteHandler(callItem, note, isConfirmation));
            dispatch(setChangeStatusIsOpen(false));
        } catch (error) {
            console.log('error', error);
            if (error.response.status === 400) {
                dispatch(setStatusChangeError(error.response.data));
            }
        }
    };

export const getSingleCallItem =
    (callListMember: AddToCallList): AppThunk =>
    async (dispatch, getState) => {
        const { FirstName, LastName, DateOfBirth, AHCCCSID } = callListMember;
        const onboarderId = getState().onboardingWorkflowSlice.onboarderStatus.UserId;

        let DOB = isDefaultDate(DateOfBirth) ? null : moment(DateOfBirth).format('MM/DD/YYYY');
        dispatch(setIsLoading(true));
        try {
            const response = await axios.get(
                `${API_URL}/onboarding/GetSingleCallItem?lastName=${LastName?.trim()?.toUpperCase()}&firstName=${FirstName?.trim()?.toUpperCase()}&dateOfBirth=${DOB}&ahcccsId=${AHCCCSID}&onboarderId=${onboarderId}`
            );
            dispatch(setAddToCallListResults(response.data));
            dispatch(setCallListMemberIsValid(true));
            dispatch(setIsLoading(false));
        } catch (error) {
            console.log(error);

            dispatch(setCallListMemberIsValid(false));
            dispatch(setIsLoading(false));
        }
    };

export const saveAndFinish =
    (callItem: CallItem, documentNote: DocumentOnboarding, isConfirmation: boolean): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setIsLoading(true));
        try {
            const { numberOfDays, onboarderStatus } = getState().onboardingWorkflowSlice;

            const callItemCopy = prepareCallItemCopy(callItem);
            await updateEditableActivityNotes(callItem.ActivityNotes);
            dispatch(updateCallItem(callItemCopy));
            await insertDocumentOnboarding(documentNote);

            if (isConfirmation) {
                dispatch(handleConfirmation(numberOfDays));
            } else {
                dispatch(handleNonConfirmation(onboarderStatus, callItemCopy));
            }
            dispatch(setIsLoading(false));
        } catch (error) {
            console.error('Error:', error);
            dispatch(setIsLoading(false));
        }
    };

const prepareCallItemCopy = (callItem: CallItem): CallItem => {
    let callItemCopy = copyObject(callItem);
    callItemCopy.AssignedOnboarderId = null;
    callItemCopy.CallAttempted = false;
    return callItemCopy;
};

const updateEditableActivityNotes = async (activityNotes: any[]) => {
    const editableActivityNotes = activityNotes.filter((item: any) => item.Editable).map((item: any) => ({ ...item, Editable: false }));
    await axios.post(`${API_URL}/onboarding/UpdateNotes`, editableActivityNotes);
};

const insertDocumentOnboarding = async (documentNote: DocumentOnboarding) => {
    await axios.post(`${API_URL}/documentonboarding/insertdocumentonboarding`, documentNote);
};

export const handleConfirmation =
    (numberOfDays: number): AppThunk =>
    async (dispatch) => {
        dispatch(getOnboardingConfirmations(numberOfDays));
    };

export const handleNonConfirmation =
    (onboarderStatus: any, callItemCopy: CallItem): AppThunk =>
    (dispatch) => {
        const updatedOnboarderStatus = {
            ...onboarderStatus,
            CallList: onboarderStatus.CallList.filter((item: any) => item.Id !== callItemCopy.Id),
        };
        dispatch(setOnboarderStatus(updatedOnboarderStatus));
        if (updatedOnboarderStatus.CallList.length > 0) {
            dispatch(setCallItem(updatedOnboarderStatus.CallList[0]));
            dispatch(setSelectedPhone(returnSelectedPhone(updatedOnboarderStatus.CallList[0].Phones)));
        } else {
            dispatch(setCallItem(new CallItem()));
            dispatch(getOnboarderStatus(onboarderStatus.UserId));
        }
    };

export const getOnboardingConfirmations =
    (numberOfDays: number): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setIsLoading(true));
        try {
            const response = await axios.get(`${API_URL}/onboarding/GetOnboardingConfirmations?numberOfDays=${numberOfDays}`);
            dispatch(setCallItemConfirmations(response.data));
            if (response.data.length > 0) {
                dispatch(setSelectedPhone(returnSelectedPhone(response.data[0].Phones)));
                dispatch(setConfirmationCallItem(response.data[0]));
            } else {
                dispatch(setConfirmationCallItem(new CallItem()));
            }
            dispatch(setIsLoading(false));
        } catch (error) {
            console.log('error', error);
            dispatch(setIsLoading(false));
        }
    };

export const checkAppointmentStatus =
    (appointmentId: string): AppThunk =>
    async (dispatch, getState) => {
        try {
            const response = await axios.get(`${API_URL}/onboarding/checkappointmentstatus?appointmentId=${appointmentId}`);
            if (response.data !== AppointmentStatusEnum.None) {
                dispatch(setShowAppointmentIsConfirmed(true));
            }
            dispatch(setAppointmentStatus(response.data));
        } catch (error) {
            console.log('error', error);
        }
    };

export const closeScheduleModalHandler =
    (callItem: CallItem, isConfirmation: boolean): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setIsLoading(true));
        const onboarderStatus = getState().onboardingWorkflowSlice.onboarderStatus;
        if (isConfirmation) {
            dispatch(setShowScheduleAppointment(false));
            dispatch(setIsLoading(false));
        } else {
            const response = await axios.get(`${API_URL}/onboarding/getonboardernotesbymemberid?memberId=${callItem.MemberId}`);
            const newValue = { ...callItem, ActivityNotes: response.data };
            const updatedOnboarderStatus = {
                ...onboarderStatus,
                CallList: onboarderStatus.CallList.map((ci) => {
                    return ci.Id === newValue.Id ? newValue : ci;
                }),
            };
            dispatch(setCallItem(newValue));
            dispatch(setOnboarderStatus(updatedOnboarderStatus));
            dispatch(getScheduledCount(onboarderStatus.Id));
            dispatch(setShowScheduleAppointment(false));
            dispatch(setIsLoading(false));
        }
    };

export const getOnboardingProviders = (): AppThunk => async (dispatch, getState) => {
    try {
        const response = await axios.get(`${API_URL}/onboarding/getonboardingproviders`);
        const onboardersList = response.data.sort((a, b) => a.Username.localeCompare(b.Username));
        dispatch(setOnboardingProviders(onboardersList));
    } catch (error) {
        console.log('error', error);
    }
};

export const getCallerHistory =
    (onboarderId: string, startDate: Date, endDate: Date): AppThunk =>
    async (dispatch) => {
        try {
            const response = await axios.get(
                `${API_URL}/onboarding/GetCallerHistory?onboarderId=${onboarderId}&startDate=${moment(startDate).utc().format()}&endDate=${moment(endDate)
                    .utc()
                    .format()}`
            );
            dispatch(_setCallerHistory(response.data));
        } catch (error) {
            console.log('error', error);
            dispatch(handleErrorMessage(error));
        }
    };

export const fetchOnboarderStatuses = (): AppThunk => async (dispatch) => {
    const response = await axios.get(`${API_URL}/onboarding/GetOnboarderStatusList`);
    dispatch(_setAllOnboarders(response.data));
};

export const updateCallItem =
    (callItem: CallItem): AppThunk =>
    async () => {
        await axios.post(`${API_URL}/onboarding/UpdateCallItem`, callItem);
    };

export const exportCallHistoryReport =
    (selectedOnboarder: OnboarderStatus, startDate: Date, endDate: Date): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setExporting(true));
        const response = await axios.post(
            `${API_URL}/onboardingreport/ExportCallerHistory?onboarderId=${selectedOnboarder.Id}&startDate=${moment(startDate).utc().format()}&endDate=${moment(
                endDate
            )
                .utc()
                .format()}`
        );
        const blob = b64toBlob(response.data.base64String, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        const blobUrl = URL.createObjectURL(blob);
        const a = document.createElement('a');
        const fileName = selectedOnboarder?.Username + '-Call-History-Report_' + startDate.toLocaleDateString() + '-' + endDate.toLocaleDateString();
        document.body.appendChild(a);
        a.href = blobUrl;
        a.download = fileName + '.xlsx';
        a.click();
        window.URL.revokeObjectURL(blobUrl);
        dispatch(setExporting(false));
    };

export default onboardingWorkflowSlice.reducer;
