import { Box, Stack, Typography } from '@mui/material';
import { CalendarEventData } from '../../Calendar/types/CalendarEventData';
import SwapSelectionCheckbox from './SwapSelectionCheckbox';

export interface ISwapListProps {
    providerName: string;
    eventList: CalendarEventData[];
}

const SwapList = (props: ISwapListProps) => {
    const { providerName, eventList } = props;
    return (
        <Stack
            justifyContent={'flex-start'}
            alignItems={'center'}
            spacing={2}
            style={{
                flexGrow: 1,
                overflowY: 'auto',
                width: '100%',
            }}
        >
            <Typography variant={'body1'} style={{ textAlign: 'center', width: '100%' }}>
                {providerName}
            </Typography>
            {eventList.map((data) => (
                <Box key={data.id} style={{ width: '100%' }}>
                    {' '}
                    <SwapSelectionCheckbox appointment={data} />
                </Box>
            ))}
        </Stack>
    );
};

export default SwapList;
