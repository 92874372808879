import axios, { AxiosResponse } from 'axios';
import Appointment from '../../../Models/Scheduling/Appointment.model';
import { apiString, appointmentStatusEnums, appointmentTypeEnums, rescheduledEventColor } from '../../../utils/constants';
import { appointmentStatusMap, appointmentTypeMap } from '../../../utils/mappings';
import { formatAppointmentTime, formatDateMMDDYYYY } from '../../../utils/timeFormat';
import qs from 'qs';
import { RescheduledNonRecurrenceDto } from '../../../Models/Scheduling/RescheduledNonRecurrenceDto.model';
import { RescheduledRecurrenceDto } from '../../../Models/Scheduling/RescheduledRecurrenceDto.model';
import { CreatedInstanceDto } from '../../../Models/Scheduling/CreatedInstanceDto.model';
import { CalendarEventData } from '../types/CalendarEventData';
import { ProviderTypeEnum } from '../../../Enum/ProviderTypeEnum';
import { OverbookResult } from '../../../Models/Scheduling/OverbookResult.model';

export const getEventTitle = (item: Appointment) => {
    let title: string = 'N/A';
    if (item.ProviderType === ProviderTypeEnum.AllInternal) {
        title = 'All Internal Providers';
    } else if (item.MemberName === null || item.MemberName === undefined) {
        title = 'Non-member Appointment';
    } else {
        title = `${item.MemberName}` ?? 'No Name';
    }
    title += ` - ${formatAppointmentTime(new Date(item.startDate))}`;

    if (item.RescheduledAppointmentId !== null && item.RescheduledAppointmentId !== undefined) {
        title = `(Rescheduled) ${title}`;
    }

    if (item.status === appointmentStatusMap.ProviderUnavailable.value) {
        title = `(Provider Leave) ${title}`;
    }

    if (item.appointmentType === appointmentTypeMap.TeamMeeting.value) {
        title = `(Team Meeting) ${title}`;
    }
    return title;
};

export const mapEventData = (item: Appointment) => {
    const statusEnum = appointmentStatusEnums.find((x) => x.value === item.status);
    let eventColor = statusEnum?.eventColor ?? 'black';
    if (item.RescheduledAppointmentId !== null && item.RescheduledAppointmentId !== undefined) {
        eventColor = rescheduledEventColor;
    }
    const title = getEventTitle(item);
    const newItem = {
        ...item,
        id: item.Id,
        start: new Date(item.startDate),
        end: new Date(item.endDate),
        title,
        color: eventColor,
    };

    return newItem;
};

export const getEventDuration = (appointmentType: number, providerRoles: string[]) => {
    const appointmentTypeEnum = appointmentTypeEnums.find((x) => x.value === appointmentType);
    if (appointmentTypeEnum === undefined || appointmentTypeEnum === null) return 30;
    const { durationTimes } = appointmentTypeEnum;
    let duration = durationTimes.default;
    if (Object.keys(durationTimes).length > 1 && providerRoles.length > 0) {
        const maxDuration = providerRoles.reduce((max, role) => {
            const roleDuration = durationTimes[role];
            if (roleDuration === undefined) return max;
            return roleDuration > max ? roleDuration : max;
        }, durationTimes.default);
        duration = maxDuration;
    }
    return duration;
};

export const getContrastYIQ = (hexcolor: string) => {
    // very common function to get the contrast color for a given hex code
    // https://stackoverflow.com/questions/11867545/change-text-color-based-on-brightness-of-the-covered-background-area
    hexcolor = hexcolor.replace('#', '');
    const r = parseInt(hexcolor.substr(0, 2), 16);
    const g = parseInt(hexcolor.substr(2, 2), 16);
    const b = parseInt(hexcolor.substr(4, 2), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq > 125 ? 'black' : 'white';
};

export const getMonthBounds = (date: Date): { firstDate: Date; lastDate: Date } => {
    const firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return { firstDate, lastDate };
};

export const setExclusionDateOnAppointment = (appointment: Partial<CalendarEventData>, exclusionDate: Date) => {
    // Replace the startDate and endDate with the exclusion date
    const start = new Date(appointment.startDate);
    const end = new Date(appointment.endDate);
    const updatedAppointment = {
        ...appointment,
        startDate: new Date(exclusionDate.getFullYear(), exclusionDate.getMonth(), exclusionDate.getDate(), start.getHours(), start.getMinutes()),
        endDate: new Date(exclusionDate.getFullYear(), exclusionDate.getMonth(), exclusionDate.getDate(), end.getHours(), end.getMinutes()),
    };

    return updatedAppointment;
};

export const sendSurvey = async (appointmentId: string) => {
    const response = await axios.post(`${apiString}/survey/sendsurvey/${appointmentId}`, {});
    return response;
};

export const createEvent = async (newEvent: Partial<Appointment>): Promise<AxiosResponse<Appointment>> => {
    return await axios.post<Appointment>(`${apiString}/Scheduling/Create`, newEvent);
};

export const updateEvent = async (modifiedEvent: Partial<Appointment>): Promise<AxiosResponse<Appointment>> => {
    return await axios.put<Appointment>(`${apiString}/Scheduling/Update`, modifiedEvent);
};

export const deleteEvent = async (eventId: string): Promise<AxiosResponse> => {
    return await axios.delete(`${apiString}/scheduling/${eventId}`);
};

export const rescheduleNonRecurringEvent = async (rescheduledEvent: Partial<Appointment>): Promise<AxiosResponse<RescheduledNonRecurrenceDto>> => {
    return await axios.post<RescheduledNonRecurrenceDto>(`${apiString}/scheduling/Reschedule`, rescheduledEvent);
};

export const rescheduleRecurringEvent = async (
    rescheduledEvent: Partial<Appointment>,
    exclusionDate: Date,
    instanceDate: Date
): Promise<RescheduledRecurrenceDto> => {
    const recurringEventCopy: Partial<Appointment> = {
        ...rescheduledEvent,
        startDate: new Date(rescheduledEvent.startDate),
        endDate: new Date(rescheduledEvent.endDate),
    };
    const cancelResponse = await cancelRecurringInstanceEvent(recurringEventCopy, exclusionDate);
    const { data: cancelData } = cancelResponse;
    const newEvent: Partial<Appointment> = { ...recurringEventCopy, Id: cancelData.CreatedInstanceAppointment.Id };
    const rescheduleResponse = await rescheduleNonRecurringEvent(mapEventData(newEvent as Appointment));
    const { data: rescheduleData } = rescheduleResponse;
    let rescheduledRecurrenceDto: RescheduledRecurrenceDto = {
        OriginalRecurrenceAppointment: cancelData.OriginalRecurrenceAppointment,
        RescheduledAppointment: rescheduleData.RescheduledAppointment,
        OriginalInstanceAppointment: rescheduleData.OriginalAppointment,
    };

    return rescheduledRecurrenceDto;
};

export const confirmRecurringInstanceEvent = async (
    recurringEvent: Partial<CalendarEventData>,
    exclusionDate: Date
): Promise<AxiosResponse<CreatedInstanceDto>> => {
    let recurringEventCopy: Partial<CalendarEventData> = {
        ...recurringEvent,
        status: appointmentStatusMap.Confirmed.value,
    };
    recurringEventCopy = setExclusionDateOnAppointment(recurringEventCopy, exclusionDate);
    return await axios.post<CreatedInstanceDto>(`${apiString}/scheduling/CreateInstanceAppointment`, recurringEventCopy, {
        params: { originalInstanceDate: formatDateMMDDYYYY(exclusionDate) },
    });
};

export const cancelRecurringInstanceEvent = async (recurringEvent: Partial<Appointment>, exclusionDate: Date): Promise<AxiosResponse<CreatedInstanceDto>> => {
    let recurringEventCopy: Partial<CalendarEventData> = {
        ...recurringEvent,
        status: appointmentStatusMap.Cancelled.value,
    };
    recurringEventCopy = setExclusionDateOnAppointment(recurringEventCopy, exclusionDate);
    return await axios.post<CreatedInstanceDto>(`${apiString}/scheduling/CreateInstanceAppointment`, recurringEventCopy, {
        params: { originalInstanceDate: formatDateMMDDYYYY(exclusionDate) },
    });
};

export const getAppointmentById = async (appointmentId: string) => await axios.get(`${apiString}/scheduling/GetAppointmentById/${appointmentId}`);

export const getCurrentUsersSchedule = async (firstDate: Date, lastDate: Date): Promise<AxiosResponse<Appointment[]>> => {
    const formattedStartDate = formatDateMMDDYYYY(firstDate);
    const formattedEndDate = formatDateMMDDYYYY(lastDate);

    return await axios.get(`${apiString}/scheduling/GetMySchedulesTabAppointments`, {
        params: { start: formattedStartDate, end: formattedEndDate },
    });
};

export const getExternalProviderScheduleByNameAndDateRange = async (
    firstDate: Date,
    lastDate: Date,
    externalProviderName: string
): Promise<AxiosResponse<Appointment[]>> => {
    return await axios.get(`${apiString}/scheduling/GetExternalProviderAppointmentsByNameAndDateRange`, {
        params: {
            providerName: externalProviderName,
            start: formatDateMMDDYYYY(firstDate),
            end: formatDateMMDDYYYY(lastDate),
        },
    });
};

export const getMemberAndProviderScheduleById = async (
    memberId: string,
    firstDate: Date,
    lastDate: Date,
    providerIds: string[]
): Promise<AxiosResponse<Appointment[]>> => {
    return await axios.get(`${apiString}/scheduling/GetMemberAppointmentsAndProviderAppointmentsById`, {
        params: {
            memberId,
            start: formatDateMMDDYYYY(firstDate),
            end: formatDateMMDDYYYY(lastDate),
            providerIds,
        },
        paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'repeat' });
        },
    });
};

export const getAllProvidersSchedulesInDateRange = async (firstDate: Date, lastDate: Date): Promise<AxiosResponse<Appointment[]>> => {
    return await axios.get(`${apiString}/scheduling/GetAllProviderAppointmentsInDateRange`, {
        params: { start: formatDateMMDDYYYY(firstDate), end: formatDateMMDDYYYY(lastDate) },
    });
};

export const getSelectedProviderSchedules = async (firstDate: Date, lastDate: Date, providerIds: string[]): Promise<AxiosResponse<Appointment[]>> => {
    return await axios.get(`${apiString}/scheduling/getmemberappointmentsbyproviderid`, {
        params: { start: formatDateMMDDYYYY(firstDate), end: formatDateMMDDYYYY(lastDate), providerIds },
        paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'repeat' });
        },
    });
};

export const getMemberSchedule = async (memberId: string, firstDate: Date, lastDate: Date): Promise<AxiosResponse<Appointment[]>> => {
    return await axios.get(`${apiString}/scheduling/GetMemberChartAppointments`, {
        params: { memberId, start: formatDateMMDDYYYY(firstDate), end: formatDateMMDDYYYY(lastDate) },
    });
};

export const getProvidersOverbookStatusForDates = async (
    providerIds: string[],
    firstDate: Date,
    lastDate: Date,
    appointmentId: string | null
): Promise<AxiosResponse<OverbookResult[]>> => {
    return await axios.post(`${apiString}/scheduling/GetOverbookStatuses`, providerIds, {
        params: { startDate: firstDate, endDate: lastDate, appointmentId },
    });
};

export const getSwappableAppointmentList = async (providerId: string, firstDate: Date, lastDate: Date): Promise<AxiosResponse<Appointment[]>> => {
    return await axios.get(`${apiString}/scheduling/GetSwappableAppointmentList`, {
        params: { providerId, startDate: firstDate, endDate: lastDate },
    });
};
